/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

import Icon from './components/Icon';
import LoadingIcon from './components/LoadingIcon';
import { AFIX, COMPONENT, SIZE, SKIN, THEME, TYPE } from './constants';

const getConfig = ({ component, href, onClick, target, targetId, to, type }) => {
  let config = {};

  switch (component) {
    case COMPONENT.ROUTER_LINK:
      config = {
        configProps: { to, onClick },
        Component: Link,
      };
      break;
    case COMPONENT.SCROLL_TO:
      config = {
        configProps: {
          to: targetId,
          duration: 500,
          smooth: true,
          onClick,
        },
        Component: ScrollTo,
      };
      break;
    case COMPONENT.LINK:
      config = {
        configProps: {
          href,
          target,
          onClick,
        },
        Component: 'a',
      };
      break;
    case COMPONENT.SPAN:
      config = {
        configProps: {},
        Component: 'span',
      };
      break;
    case COMPONENT.BUTTON:
      config = {
        configProps: {
          onClick,
          type,
        },
        Component: 'button',
      };
      break;
    // no default
  }

  return config;
};

const Button = (props) => {
  const { Component, configProps } = getConfig(props);

  const {
    afix,
    children,
    className,
    icon,
    isDisabled,
    isFullWidth,
    isLoading,
    isUnderlined,
    loadingCopy,
    shadow,
    hasLoadingCopy,
    hasLoadingIcon,
    size,
    skin,
    theme,
    testId,
    id,
  } = props;

  const loadingText = hasLoadingCopy ? loadingCopy : null;

  return (
    <Component
      {...configProps}
      id={id}
      disabled={isDisabled || isLoading}
      data-test={testId}
      className={classNames(
        'btn',
        `btn--${skin}`,
        `btn--${theme}`,
        `btn--${size}`,
        `btn--${afix}`,
        { [`btn--shadow-${shadow}`]: shadow },
        { 'btn--full-width': isFullWidth },
        { 'btn--disabled': isDisabled && !isLoading },
        { 'btn--loading': isLoading },
        { 'btn--has-icon': !!icon || isLoading },
        { 'btn--is-underlined': isUnderlined },
        className,
      )}
    >
      {isLoading && hasLoadingIcon ? (
        <LoadingIcon size={size} position={afix} />
      ) : (
        icon && <Icon name={icon} size={size} position={afix} />
      )}

      <span className={classNames('btn__copy', `btn__copy--${size}`)}>{isLoading ? loadingText : children}</span>
    </Component>
  );
};

Button.propTypes = {
  afix: PropTypes.oneOf([AFIX.PREFIX, AFIX.SUFFIX, AFIX.NONE]),
  children: PropTypes.node,
  className: PropTypes.string,
  hasLoadingCopy: PropTypes.bool,
  hasLoadingIcon: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  loadingCopy: PropTypes.string,
  shadow: PropTypes.oneOf([0, 1, 2, 3, 4]),
  size: PropTypes.oneOf([SIZE.LARGE, SIZE.MEDIUM, SIZE.SMALL, SIZE.TINY]),
  skin: PropTypes.oneOf([SKIN.PRIMARY, SKIN.SECONDARY, SKIN.PLAIN, SKIN.LINK]),
  target: PropTypes.string,
  theme: PropTypes.oneOf([THEME.CORAL, THEME.DARK, THEME.JADE, THEME.COAL]),
  testId: PropTypes.string,
  type: PropTypes.oneOf([TYPE.BUTTON, TYPE.SUBMIT]),
  component: PropTypes.oneOf([
    COMPONENT.BUTTON,
    COMPONENT.LINK,
    COMPONENT.SCROLL_TO,
    COMPONENT.ROUTER_LINK,
    COMPONENT.SPAN,
  ]),
  onClick: (props) => {
    const { component, isDisabled, type, onClick } = props;
    if (component === COMPONENT.BUTTON && !isDisabled) {
      if (type !== TYPE.SUBMIT && !onClick) {
        return new Error(`Button: Please prop 'onClick' \n
         onClick: func.isRequired `);
      }

      if (onClick && typeof onClick !== 'function') {
        return new Error(`Expected 'onClick' listener to be a function`);
      }
    }

    if (component === COMPONENT.ROUTER_LINK && !isDisabled) {
      if (onClick && typeof onClick !== 'function') {
        return new Error(`Expected 'onClick' listener to be a function`);
      }
    }
    return null;
  },
  href: (props) => {
    const { component, href } = props;
    if (component === COMPONENT.LINK) {
      if (!href) {
        return new Error(
          `Please provide prop 'href' for button type ${COMPONENT.LINK}. \n
            href: string.isRequired`,
        );
      }
      if (typeof href !== 'string') {
        return new Error(
          `Expected 'href' to be a string \n
            href: string.isRequired`,
        );
      }
    }
    return null;
  },
  targetId: (props) => {
    const { component, targetId } = props;
    if (component === COMPONENT.SCROLL_TO) {
      if (!targetId) {
        return new Error(
          `Please provide prop 'targetId' for button type ${COMPONENT.SCROLL_TO}. \n
            targetId: string.isRequired`,
        );
      }
      if (typeof targetId !== 'string') {
        return new Error(
          `Expected 'targetId' to be a string \n
            targetId: string.isRequired`,
        );
      }
    }
    return null;
  },
  to: (props) => {
    const { component, to } = props;
    if (component === COMPONENT.ROUTER_LINK) {
      if (!to) {
        return new Error(
          `Please provide prop 'to' for component type ${COMPONENT.ROUTER_LINK}. \n
            to: oneOfType([string, shape({})]).isRequired,`,
        );
      }

      if (typeof to !== 'string' && typeof to !== 'object') {
        return new Error(
          `Expected 'to' to be a string or an object \n
            to: oneOfType([string, shape({ to: string })]).isRequired,`,
        );
      }
    }
    return null;
  },
};

Button.defaultProps = {
  children: null,
  afix: AFIX.PREFIX,
  className: undefined,
  component: COMPONENT.BUTTON,
  hasLoadingCopy: true,
  hasLoadingIcon: true,
  href: undefined,
  icon: undefined,
  id: undefined,
  isDisabled: false,
  isFullWidth: false,
  isLoading: false,
  isUnderlined: false,
  loadingCopy: 'Loading...',
  onClick: undefined,
  shadow: undefined,
  size: SIZE.MEDIUM,
  skin: SKIN.PRIMARY,
  target: undefined,
  targetId: undefined,
  theme: THEME.CORAL,
  testId: '',
  to: undefined,
  type: TYPE.BUTTON,
};

export default Button;
