import { isObject } from 'lodash-es';

const isValidValue = (value, propName, name) =>
  (value >= 0 && value <= 8) || value === 'auto'
    ? null
    : new Error(`${propName} in ${name} is invalid. Values must be between 0 and 8 or "auto"`);

const validKeys = ['sm', 'md', 'lg', 'xlg', 'max'];
const hasValidKeys = (value, propName, name) =>
  Object.keys(value).every((key) => validKeys.includes(key))
    ? null
    : new Error(`${propName} in ${name} does not have valid keys. Keys must be one of ${validKeys}`);

const keyConflicts = {
  mh: ['mr', 'ml'],
  mv: ['mt', 'mb'],
  ma: ['mv', 'mh'],
};
const hasNoConflictingSpacers = (componentProps, propName, name) => {
  if (!keyConflicts[propName]) return null;
  return !keyConflicts[propName].some((conflictingValue) => componentProps[conflictingValue])
    ? null
    : new Error(`${propName} in ${name} may not be present with these props: ${keyConflicts[propName]}`);
};

const hasValidValues = (value, propName, name) =>
  Object.values(value).every((v) => (v >= 0 && v <= 8) || v === 'auto')
    ? null
    : new Error(`${propName} in ${name} is invalid. Values must be either between 0 and 8 or 'auto'`);

const responsiveSpacingPropType = (componentProps, propName, name) => {
  const value = componentProps[propName];

  if (!value && value !== 0) return null;

  if (isObject(value)) {
    return (
      hasValidKeys(value, propName, name) ||
      hasValidValues(value, propName, name) ||
      hasNoConflictingSpacers(componentProps, propName, name)
    );
  }

  return isValidValue(value, propName, name);
};

export default responsiveSpacingPropType;
