import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useScript from 'Hooks/useScript';

const TrustpilotWidget = ({ className, height, templateId, theme, width, ...rest }) => {
  const trustBoxRef = useRef();

  useScript('//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, []);

  return (
    <div className={className}>
      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id={templateId}
        data-businessunit-id="58b689650000ff00059d937d"
        data-style-height={height || 'auto'}
        data-style-width={width}
        data-theme={theme}
        data-stars="4,5"
        data-review-languages="en"
        {...rest}
      >
        <a href="https://uk.trustpilot.com/review/boxt.co.uk" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </div>
  );
};

TrustpilotWidget.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  templateId: PropTypes.string,
  theme: PropTypes.string,
  width: PropTypes.string,
};

TrustpilotWidget.defaultProps = {
  className: '',
  height: '245px',
  templateId: '54ad5defc6454f065c28af8b',
  theme: 'dark',
  width: '100%',
};

export default TrustpilotWidget;
