function goToUrl(url) {
  window.location.assign(url);
}

function reloadPage() {
  window.location.reload();
}

function scrollToTop() {
  window.scrollTo(0, 0);
}

export { goToUrl, reloadPage, scrollToTop };
