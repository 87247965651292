/* eslint-disable no-use-before-define */
import { createElement } from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

const formatElement = (element, componentClassname) => {
  const props = {
    key: uuid(),
    className: classNames(componentClassname, element.className),
    ...element.props,
  };
  const content =
    typeof element.content === 'string' ? element.content : formatElements(element.content, componentClassname);
  return createElement(element.type, props, content);
};

const formatElements = (elements, componentClassname) => elements.map((el) => formatElement(el, componentClassname));

export default formatElements;
