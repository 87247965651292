import React from 'react';
import { H5 } from '@boxt/design-system';
import { createPopup } from '@typeform/embed';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import formatElements from 'Helpers/schema';
import Button from 'Patterns/controls/Button';
import H3 from 'Patterns/copy/H3';
import ImgixProxy from 'Patterns/ui/ImgixProxy';

const Product = ({
  product: {
    title,
    img,
    tag,
    enabledCtaText,
    imgUrl,
    ctaUrl,
    linkUrl,
    key,
    sizes,
    srcSetOptions,
    description,
    buttonTheme,
    formId = '',
  },
  linkText,
  isDisabled,
}) => {
  const { toggle: toggleTypeform } = createPopup(formId, {
    size: 70,
    opacity: 100,
    iframeProps: { title: 'BOXT - Register Your Interest' },
    autoClose: 6000,
    transitiveSearchParams: true,
    medium: 'snippet',
  });

  const ImageWrapper = isDisabled ? 'div' : 'a';
  const hasLink = linkUrl;
  return (
    <div className="g-col g-span12 g-span6--mid">
      <div className={classNames('product-selection-product', `product-selection-product--${key}`)}>
        {tag && (
          <H5 boxtTheme="dark" className="product-selection-product__tag">
            {tag}
          </H5>
        )}
        <ImageWrapper
          {...(formId ? { onClick: toggleTypeform } : { href: imgUrl })}
          className="product-selection-product__img-wrapper"
        >
          <ImgixProxy
            className={classNames('product-selection-product__img', `product-selection-product__img--${key}`)}
            src={require(`Images/product-selection/${img}@2x.png`)}
            srcSetOptions={srcSetOptions}
            sizes={sizes}
          />
        </ImageWrapper>
        <div className="product-selection-product__content_wrapper">
          <H3 className="product-selection-product__title">{title}</H3>
          {description && (
            <div className="product-selection-product__description-wrapper">
              {formatElements(description, 'product-selection-product__description')}
            </div>
          )}
          {(ctaUrl || formId) && (
            <Button
              className="product-selection-product__btn"
              theme={buttonTheme || 'jade'}
              isDisabled={isDisabled}
              {...(formId ? { onClick: toggleTypeform } : { href: ctaUrl, component: 'link' })}
            >
              {enabledCtaText}
            </Button>
          )}
          {hasLink && (
            <Button
              id="product-selection-product__more-link"
              component="link"
              href={linkUrl}
              skin="link"
              isDisabled={isDisabled}
            >
              {linkText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string,
    formId: PropTypes.string,
    key: PropTypes.string,
    tag: PropTypes.string,
    img: PropTypes.string,
    enabledCtaText: PropTypes.string,
    imgUrl: PropTypes.string,
    ctaUrl: PropTypes.string,
    linkUrl: PropTypes.string,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        content: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              content: PropTypes.string,
            }),
          ),
        ]),
      }),
    ),
    buttonTheme: PropTypes.string,
  }).isRequired,
  linkText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

Product.defaultProps = {
  isDisabled: false,
};

export default Product;
