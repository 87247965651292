if (!global.Intl) {
  global.Intl = require('intl');
  require('intl/locale-data/jsonp/en');
}

const penceToPounds = (priceInPence) => priceInPence / 100;

const currencyFormatter = (priceInPence, quantity = 1, precision = 2, stripDoubleZeros = true) => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: precision,
  });

  const priceInPounds = penceToPounds(Math.abs(priceInPence * quantity));
  const formattedPrice = formatter.format(priceInPounds);
  return stripDoubleZeros ? formattedPrice.replace(/\D00$/, '') : formattedPrice;
};

export { penceToPounds };
export default currencyFormatter;
