import React from 'react';
import PropTypes from 'prop-types';

import { AFIX } from '../../constants';
import Icon from '../Icon';

const LoadingIcon = ({ size, position }) => <Icon name="loader" position={position} size={size} isLoading />;

LoadingIcon.propTypes = {
  size: PropTypes.string.isRequired,
  position: PropTypes.oneOf([AFIX.PREFIX, AFIX.SUFFIX, AFIX.NONE]),
};

LoadingIcon.defaultProps = {
  position: AFIX.PREFIX,
};

export default LoadingIcon;
