/* eslint-disable no-underscore-dangle */
const sendToCs = (key, value) => {
  if (typeof window !== 'undefined') {
    window._uxa = window._uxa || [];
    window._uxa.push([
      'trackDynamicVariable',
      {
        key,
        value,
      },
    ]);
  }
};
export default sendToCs;
