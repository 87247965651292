const ADDONS = 'addons';
const AIR_CON = 'air_con';
const BOILER = 'boiler';
const CONTROL = 'control';
const EV_CHARGER = 'ev_charger';
const HEAT_PUMP = 'heat_pump';
const BOILER_SUBSCRIPTION = 'boiler_subscription';
const BOILER_REPAIR = 'boiler_repair';
const HOME_COVER = 'home_cover';
const RADIATOR = 'radiator';
const SMART_HOME = 'smart_home';
const SOLAR = 'solar';

const MAIN_PACKAGES = [BOILER, AIR_CON];

const PRODUCTS = {
  [AIR_CON]: 'AC',
  [EV_CHARGER]: 'EV',
  [BOILER]: 'Boiler',
};

export {
  ADDONS,
  AIR_CON,
  BOILER,
  BOILER_REPAIR,
  BOILER_SUBSCRIPTION,
  CONTROL,
  EV_CHARGER,
  HEAT_PUMP,
  HOME_COVER,
  MAIN_PACKAGES,
  PRODUCTS,
  RADIATOR,
  SMART_HOME,
  SOLAR,
};
