import React from 'react';
import { FlagshipProvider, LogLevel } from '@flagship.io/react-sdk';

import onVisitorExposedHelper from 'Helpers/onVisitorExposedHelper';
import useSegmentId from 'Hooks/useSegmentId';

const FsProvider = ({ children, flagshipEnvId, flagshipApiKey }) => {
  const { segmentId } = useSegmentId();

  return (
    <FlagshipProvider
      envId={flagshipEnvId}
      apiKey={flagshipApiKey}
      logLevel={LogLevel.ERROR}
      visitorData={{ id: segmentId, isAuthenticated: Boolean(segmentId) }}
      onVisitorExposed={onVisitorExposedHelper}
    >
      {children}
    </FlagshipProvider>
  );
};

export default FsProvider;
