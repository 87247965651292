import React from 'react';
import ReactDOM from 'react-dom';

import ProductSelection from 'Features/ProductSelection';
import FsProvider from 'Features/ProductSelection/FsProvider';

const Init = (el) => {
  const isHomeCoverEnabled = JSON.parse(el.getAttribute('data-is-home-cover-enabled'));

  const flagshipApiKey = el.getAttribute('data-flagship-api-key');
  const flagshipEnvId = el.getAttribute('data-flagship-env-id');

  ReactDOM.render(
    <FsProvider flagshipEnvId={flagshipEnvId} flagshipApiKey={flagshipApiKey}>
      <ProductSelection headline="" isHomeCoverEnabled={isHomeCoverEnabled} hasExtendedProductList />
    </FsProvider>,
    el,
  );
};

export default Init;
