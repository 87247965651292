import React from 'react';

const Background = ({ content }) => {
  const {
    background_img_desktop,
    background_img_desktop_2x,
    background_img_mobile,
    background_img_mobile_2x,
    background_img_tablet,
    background_img_tablet_2x,
    background_gradient_colour_1,
    background_gradient_colour_2,
    background_gradient_opacity,
    background_image_alt,
  } = content;

  const tintStyle = {
    opacity: background_gradient_opacity || 1,
    background: `linear-gradient(${background_gradient_colour_1}, ${background_gradient_colour_2})`,
  };

  return (
    <div className="hero__butter-cms-background">
      <picture>
        <source media="(max-width: 42rem)" srcSet={`${background_img_mobile} 1x, ${background_img_mobile_2x} 2x`} />
        <source media="(max-width: 66rem)" srcSet={`${background_img_tablet} 1x, ${background_img_tablet_2x} 2x`} />
        <img
          src={background_img_desktop}
          alt={background_image_alt || 'Hero image'}
          srcSet={`${background_img_desktop} 1x ,${background_img_desktop_2x} 2x`}
        />
      </picture>
      <div className="hero__image-tint" style={tintStyle} />
    </div>
  );
};

export default Background;
