import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Copy from '../components';

const H1 = forwardRef(({ className, ...rest }, ref) => (
  <Copy ref={ref} className={classnames('h1', className)} {...rest} />
));

H1.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
};

H1.defaultProps = {
  as: 'h1',
  className: undefined,
};

H1.displayName = 'H1';

export default H1;
