import React from 'react';
import ReactDOM from 'react-dom';

import AsFeaturedIn from 'Features/AsFeaturedIn';

const Init = (el) => {
  ReactDOM.render(<AsFeaturedIn />, el);
};

export default Init;
