import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import getSpacing from './helpers';
import responsiveSpacingPropType from './propTypes';

const Spacing = ({
  as,
  children,
  className,
  ma, // margin all
  mb, // margin bottom
  mh, // margin horizontal
  ml, // margin left
  mr, // margin right
  mt, // margin top
  mv, // margin vertical
  ...componentProps
}) => {
  const Component = as;
  const spacingClassNames = getSpacing({ mh, mv, mt, mb, ml, mr, ma });
  return (
    <Component className={classnames(spacingClassNames, className)} {...componentProps}>
      {children}
    </Component>
  );
};

Spacing.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  mh: responsiveSpacingPropType,
  mv: responsiveSpacingPropType,
  ma: responsiveSpacingPropType,
  mt: responsiveSpacingPropType,
  mb: responsiveSpacingPropType,
  mr: responsiveSpacingPropType,
  ml: responsiveSpacingPropType,
  className: PropTypes.string,
};

Spacing.defaultProps = {
  as: 'div',
  mh: null,
  mv: null,
  ma: null,
  mt: null,
  mb: null,
  mr: null,
  ml: null,
  className: '',
};

export default Spacing;
