import React from 'react';
import ReactDOM from 'react-dom';

import PricePromise from 'Features/PricePromise/module';

const Init = (el) => {
  ReactDOM.render(<PricePromise />, el);
};

export default Init;
