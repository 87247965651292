import React from 'react';
import PropTypes from 'prop-types';

import data from 'Features/PricePromise/module/PricePromise.data';
import Button from 'Patterns/controls/Button';
import H2 from 'Patterns/copy/H2';
import Paragraph from 'Patterns/copy/Paragraph';
import ImgixProxy from 'Patterns/ui/ImgixProxy';

const PricePromise = ({ title, copy, cta }) => (
  <div id="price-promise" className="price-promise">
    <div className="l-container">
      <div className="g price-promise__row">
        <div className="g-col g-span12 g-span10--l g-col--centered">
          <div className="g">
            <div className="g-col g-span12 g-span5--l">
              <ImgixProxy
                className="price-promise__image"
                alt="BOXT Price promise"
                src={require('Images/price-promise/boxt-price-promise.png')}
                sizes="(min-width: 1024px) 382px, (min-width: 768px) 317px, 249px"
                srcSetOptions={{ widths: [249, 317, 382, 498, 634, 764] }}
              />
            </div>
            <div className="g-col g-span12 g-span8--m g-col--centered g-span5--l">
              <H2 className="price-promise__headline" theme="dark" bottom={7}>
                {title}
              </H2>
              <Paragraph bottom={6} size="large" theme="dark">
                {copy}
              </Paragraph>
              {cta.url && (
                <div className="price-promise__cta">
                  <Button component="link" skin="link" href={cta.url} theme="dark" isUnderlined>
                    {cta.copy}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

PricePromise.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  cta: PropTypes.shape({
    url: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
  }),
};

PricePromise.defaultProps = {
  title: data.title,
  copy: data.copy,
  cta: data.cta,
};

export default PricePromise;
