const SIZE = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const SKIN = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  PLAIN: 'plain',
  LINK: 'link',
};

const THEME = {
  CORAL: 'coral',
  DARK: 'dark',
  JADE: 'jade',
  COAL: 'coal',
};

const AFIX = {
  PREFIX: 'prefix',
  SUFFIX: 'suffix',
  NONE: 'none',
};

const TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
};

const COMPONENT = {
  BUTTON: 'button',
  LINK: 'link',
  SCROLL_TO: 'scroll-to',
  ROUTER_LINK: 'router-link',
  SPAN: 'span',
};

export { AFIX, COMPONENT, SIZE, SKIN, THEME, TYPE };
