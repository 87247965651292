import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Icon = ({ classname, name, size }) => (
  <svg {...(size && { width: size, height: size })} className={classNames('icon', classname)}>
    <use href={icons(name)} />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  classname: PropTypes.string,
};

Icon.defaultProps = {
  classname: undefined,
};

export default Icon;
