const addApplets = (applets) =>
  document.addEventListener('DOMContentLoaded', () => {
    applets.map(({ init, el }) => {
      const element = document.getElementById(el);

      if (element) {
        init(element);
      }

      return element;
    });
  });

// eslint-disable-next-line import/prefer-default-export
export { addApplets };
