import { colors, Paragraph } from '@boxt/design-system';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Slider from 'Patterns/others/Slider';

import { propositionFieldsProptypes, propositionItemProptypes } from '../../types';

const Item = ({ content }) => (
  <div className="proposition-banner__item-container">
    <div className="proposition-banner__icon-wrapper">
      <img src={content.icon} alt={content.icon_alt} />
    </div>
    <Paragraph size="small" weight="regular" boxtTheme={content.text_theme?.name || 'slate'}>
      {content.text}
    </Paragraph>
  </div>
);

Item.propTypes = {
  content: propositionItemProptypes.isRequired,
};

const PropositionBanner = ({ content, hasSpacing }) => {
  const navDiv = document.getElementById('react-global-nav');

  if (navDiv) {
    navDiv.style.paddingTop = '2rem';
  }

  return (
    <div
      id={content.id}
      className={classNames({
        'proposition-banner__wrapper': hasSpacing,
        'proposition-banner__no-spacing-wrapper': !hasSpacing,
      })}
      style={{ backgroundColor: colors.platinum.lighter }}
    >
      <div className="proposition-banner__sm-wrapper">
        <Slider
          centerMode={false}
          autoplay
          autoplaySpeed={typeof content.animation_delay !== 'string' ? content.animation_delay : 6000}
        >
          {content.proposition_item.map((item) => (
            <div key={item.text}>
              <div className="proposition-banner__slide">
                <Item key={item.text} content={item} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="proposition-banner__md-wrapper">
        {content.proposition_item.map((item) => (
          <Item key={item.text} content={item} />
        ))}
      </div>
    </div>
  );
};

PropositionBanner.propTypes = {
  content: propositionFieldsProptypes.isRequired,
  hasSpacing: PropTypes.bool,
};
PropositionBanner.defaultProps = {
  hasSpacing: true,
};

export default PropositionBanner;
