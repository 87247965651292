import {
  AIR_CON,
  BOILER,
  BOILER_REPAIR,
  BOILER_SUBSCRIPTION,
  EV_CHARGER,
  HEAT_PUMP,
  HOME_COVER,
  SMART_HOME,
  SOLAR,
} from 'Constants/products';

const getProductList = (hasExtendedProductList) => ({
  [BOILER]: {
    title: 'Boilers',
    key: BOILER,
    img: 'boiler',
    enabledCtaText: 'Get a fixed price',
    imgUrl: '/boilers',
    ctaUrl: '/boilers',
    linkUrl: '/boilers',
    sizes: '(min-width: 1024px) 400px, 200px',
    srcSetOptions: { widths: [200, 400, 800] },
  },
  [HOME_COVER]: {
    title: 'Home cover',
    key: hasExtendedProductList ? 'home_cover_shield' : HOME_COVER,
    img: hasExtendedProductList ? 'home-cover-shield' : 'home-cover',
    enabledCtaText: 'See our plans',
    imgUrl: '/home-cover',
    ctaUrl: '/home-cover',
    linkUrl: undefined,
    sizes: '(min-width: 1024px) 320px, 210px',
    srcSetOptions: { widths: [210, 320, 420, 640] },
  },
  [SMART_HOME]: {
    title: 'Smart Home',
    key: SMART_HOME,
    img: 'smart-home',
    enabledCtaText: undefined,
    imgUrl: undefined,
    ctaUrl: undefined,
    linkUrl: undefined,
    sizes: '(min-width: 1024px) 370px, 229px',
    srcSetOptions: { widths: [229, 370, 458, 740] },
  },
  [AIR_CON]: {
    title: 'Air Conditioning',
    key: AIR_CON,
    img: 'air_con',
    enabledCtaText: 'Get a fixed price',
    imgUrl: '/find-ac',
    ctaUrl: '/find-ac',
    linkUrl: '/air-conditioning',
    sizes: '(min-width: 1024px) 540px, 309px',
    srcSetOptions: { widths: [309, 540, 618, 1080] },
  },
  [EV_CHARGER]: {
    title: 'Electric vehicle chargers',
    key: EV_CHARGER,
    img: 'ev',
    enabledCtaText: 'Get a fixed price',
    imgUrl: '/find-an-ev-charger',
    ctaUrl: '/find-an-ev-charger',
    linkUrl: '/ev-chargers',
    sizes: '(min-width: 1024px) 347px, (min-width: 768px) 185px, 180px',
    srcSetOptions: { widths: [180, 185, 347, 360, 370, 694] },
  },
  buy_boiler: {
    title: 'Buy a boiler',
    key: 'buy_boiler',
    img: 'boiler',
    enabledCtaText: 'Get a fixed price',
    description: [
      { type: 'p', content: 'Get a new boiler with next day installation.' },
      { type: 'p', content: 'Pay by card or finance' },
    ],
    imgUrl: '/find-a-boiler',
    ctaUrl: '/boilers/your-home',
    linkUrl: '/boilers',
    sizes: '(min-width: 1024px) 400px, 200px',
    srcSetOptions: { widths: [200, 400, 800] },
  },
  [BOILER_SUBSCRIPTION]: {
    title: 'Get an all-in-one boiler subscription',
    key: BOILER_SUBSCRIPTION,
    img: 'boiler_subscription',
    enabledCtaText: 'Get a fixed price',
    description: [
      {
        type: 'p',
        content: [
          {
            type: 'span',
            content: 'Get a new boiler with next day installation + unlimited repairs, replacement & servicing ',
          },
          {
            type: 'strong',
            content: 'from only £30 a month',
          },
        ],
      },
    ],
    buttonTheme: 'coal',
    imgUrl: '/boilers/subscription',
    ctaUrl: '/boilers/subscription',
    sizes: '(min-width: 1024px) 400px, 200px',
    srcSetOptions: { widths: [200, 400, 800] },
  },
  [SOLAR]: {
    title: 'Solar and battery storage',
    key: SOLAR,
    img: 'solar',
    tag: 'new',
    enabledCtaText: 'Configure your system',
    description: [
      {
        type: 'p',
        content: [
          {
            type: 'span',
            content: 'Installed starting from ',
          },
          {
            type: 'strong',
            content: '£3,613',
          },
        ],
      },
    ],
    imgUrl: '/solar',
    ctaUrl: '/solar',
    sizes: '(min-width: 1024px) 400px, 200px',
    srcSetOptions: { widths: [200, 400, 800] },
  },
  [BOILER_REPAIR]: {
    title: 'Book a repair',
    key: 'boiler_repair',
    img: 'boiler-repair',
    enabledCtaText: 'Book a repair',
    imgUrl: '/boilers/boiler-repair',
    ctaUrl: '/boilers/boiler-repair',
    sizes: '(min-width: 1024px) 400px, 200px',
    srcSetOptions: { widths: [200, 400, 800] },
  },
  [HEAT_PUMP]: {
    title: 'Hybrid heat pumps',
    key: HEAT_PUMP,
    img: 'heat-pump',
    tag: 'new',
    description: [
      {
        type: 'p',
        content: [
          {
            type: 'span',
            content: 'Save up to £77 a month',
          },
          {
            type: 'sup',
            content: '(2) ',
          },
          {
            type: 'span',
            content: 'on your energy bills with a Daikin hybrid heat pump. Installed starting from ',
          },
          {
            type: 'strong',
            content: '£5,595',
          },
        ],
      },
    ],
    enabledCtaText: 'Get a fixed price',
    sizes: '(min-width: 1024px) 400px, 200px',
    srcSetOptions: { widths: [200, 400, 800] },
    formId: 'X6lDoxNh',
  },
});

export default (isHomeCoverEnabled, hasExtendedProductList) => {
  const products = getProductList(hasExtendedProductList);

  return hasExtendedProductList
    ? [
        products.buy_boiler,
        products[BOILER_SUBSCRIPTION],
        products[SOLAR],
        products[HEAT_PUMP],
        products[AIR_CON],
        products[HOME_COVER],
        products[BOILER_REPAIR],
        products[EV_CHARGER],
      ]
    : [
        products[BOILER],
        isHomeCoverEnabled ? products[HOME_COVER] : products[SMART_HOME],
        products[AIR_CON],
        products[EV_CHARGER],
      ];
};
