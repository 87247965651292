import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Copy from '../components';

const H3 = forwardRef(({ className, ...rest }, ref) => (
  <Copy ref={ref} className={classnames('h3', className)} {...rest} />
));

H3.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
};

H3.defaultProps = {
  as: 'h3',
  className: undefined,
};

H3.displayName = 'H3';

export default H3;
