import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import generateClassNames from 'Helpers/generateClassNames';

const Copy = forwardRef(({ as, bottom, children, className, id, theme, top, align, weight, tabIndex }, ref) => {
  const Component = as;

  return (
    <Component
      id={id}
      ref={ref}
      className={classnames(
        'copy',
        { [`copy-weight--${weight}`]: weight },
        generateClassNames('copy', { bottom, top, align, theme }),
        className,
      )}
      tabIndex={tabIndex}
    >
      {children}
    </Component>
  );
});

const validAligns = PropTypes.oneOf(['center', 'left', 'right']);
const validThemes = PropTypes.oneOf([
  'coal-light',
  'coral',
  'dark',
  'error',
  'jade',
  'light',
  'slate',
  'slate-light',
  'slate-lighter',
  'success',
]);
const validSpacing = PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

Copy.propTypes = {
  align: PropTypes.oneOfType([
    validAligns,
    PropTypes.shape({
      sm: validAligns,
      md: validAligns,
      lg: validAligns,
      xlg: validAligns,
      max: validAligns,
    }),
  ]),
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'span', 'label']),
  bottom: PropTypes.oneOfType([
    validSpacing,
    PropTypes.shape({
      sm: validSpacing,
      md: validSpacing,
      lg: validSpacing,
      xlg: validSpacing,
      max: validSpacing,
    }),
  ]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape), PropTypes.shape({})]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  tabIndex: PropTypes.string,
  theme: PropTypes.oneOfType([
    validThemes,
    PropTypes.shape({
      sm: validThemes,
      md: validThemes,
      lg: validThemes,
      xlg: validThemes,
      max: validThemes,
    }),
  ]),
  top: PropTypes.oneOfType([
    validSpacing,
    PropTypes.shape({
      sm: validSpacing,
      md: validSpacing,
      lg: validSpacing,
      xlg: validSpacing,
      max: validSpacing,
    }),
  ]),
  weight: PropTypes.oneOf(['bold', 'normal', 'light']),
};

Copy.defaultProps = {
  align: undefined,
  as: 'p',
  bottom: undefined,
  className: undefined,
  id: undefined,
  tabIndex: '-1',
  theme: undefined,
  top: undefined,
  weight: undefined,
};

export default Copy;
