import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'Patterns/ui/Icon';

const BtnIcon = ({ name, position, size, isLoading }) => (
  <span className={classNames('btn__icon-wrapper', `btn__icon-wrapper--${position}`)}>
    <Icon
      name={name}
      classname={classNames('btn__icon', `btn__icon--${size}`, `btn__icon--${position}`, {
        'btn__icon--rotate': isLoading,
      })}
    />
  </span>
);

BtnIcon.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

BtnIcon.defaultProps = {
  isLoading: false,
};

export default BtnIcon;
