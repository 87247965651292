export default {
  header: 'Our partners',
  items: [
    {
      logo: 'edf',
      name: 'EDF',
    },
    {
      logo: 'worcester',
      name: 'Worcester',
    },
    {
      logo: 'bosch',
      name: 'Bosch',
    },
    {
      logo: 'google',
      name: 'Google',
    },
    {
      logo: 'everton',
      name: 'Everton',
    },
    {
      logo: 'leeds',
      name: 'Leeds',
    },
    {
      logo: 'forest',
      name: 'Forest',
    },
    {
      logo: 'itv',
      name: 'itv',
    },
    {
      logo: 'rhinos',
      name: 'Leeds Rhinos',
    },
    {
      logo: 'rangers',
      name: 'Rangers FC',
    },
    {
      logo: 'bandq',
      name: 'B&Q',
    },
    {
      logo: 'uswitch',
      name: 'uSwitch',
    },
    {
      logo: 'middlesbrough',
      name: 'Middlesbrough FC',
    },
  ],
};
