import React from 'react';
import { format, isSameDay, isTomorrow, parseISO, startOfToday } from 'date-fns';

const getDay = (parsedDate) => {
  const dayOfWeekFormat = 'EEEE';
  let day;

  if (isSameDay(parsedDate, startOfToday())) {
    day = {
      name: 'TODAY',
      dayOfWeek: false,
    };
  } else if (isTomorrow(parsedDate)) {
    day = {
      name: 'TOMORROW',
      dayOfWeek: false,
    };
  } else {
    day = {
      name: format(parsedDate, dayOfWeekFormat),
      dayOfWeek: true,
    };
  }

  return day;
};

const generateAvailabilityMessage = (date, outcode, discount) => {
  if (date) {
    const parsedDate = parseISO(date);
    const day = getDay(parsedDate);

    return (
      <>
        We can install in <span data-cs-mask>{outcode?.toUpperCase()}</span> {Boolean(day.dayOfWeek) && <>from </>}
        <strong>{day.name}</strong>
        {Boolean(discount) && (
          <>
            {' '}
            with <strong>{discount} off</strong>
          </>
        )}
      </>
    );
  }

  return null;
};

export default generateAvailabilityMessage;
