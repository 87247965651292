const sortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const sortByNumber = (a, b) => a - b;

const sortItems = (a, b, direction = sortDirection.ASC) => {
  const descending = direction !== sortDirection.ASC;

  if (a < b) return descending ? 1 : -1;
  if (a > b) return descending ? -1 : 1;
  return 0;
};

const sortByRecommended = (a, b) =>
  b.main_package.best_seller - a.main_package.best_seller ||
  b.main_package.popular_choice - a.main_package.popular_choice ||
  a.customer_price_in_pence - b.customer_price_in_pence;

export default sortItems;
export { sortByNumber, sortByRecommended, sortDirection };
