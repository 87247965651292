import React from 'react';
import PropTypes from 'prop-types';

import FeaturedInSlider from 'Patterns/others/Slider/FeaturedInSlider';

import data from './data';

const AsFeaturedIn = ({ header, items }) => (
  <FeaturedInSlider
    header={header}
    imageFolder="featured-in-companies"
    itemClassName="featured-in-slide__img--featured-in"
    slides={items}
  />
);

AsFeaturedIn.propTypes = {
  header: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({ logo: PropTypes.string.isRequired, name: PropTypes.string.isRequired })),
};

AsFeaturedIn.defaultProps = {
  header: data.header,
  items: data.items,
};

export default AsFeaturedIn;
