import { isObject } from 'lodash-es';

const createResponsiveClasses = (
  prefix,
  propName,
  responsiveOpts, // ('mh', { sm: 1, md: 2, lg: 3 })
) =>
  Object.entries(responsiveOpts) // [['sm', 1], ['md', 2], ['lg', 3]]
    .map(
      ([breakpoint, value]) =>
        // bxt-spacing--mh--1--sm
        `${prefix}--${propName}-${value}--${breakpoint}`,
    );

const createClasses = (prefix, propName, value) =>
  isObject(value) ? createResponsiveClasses(prefix, propName, value) : `${prefix}--${propName}-${value}`;

const generateClassNames = (
  prefix,
  spacingProps, // { mv: 2, mh: { sm:1, md: 2, lf: 3 } }
) =>
  Object.entries(spacingProps) // [['mv', 2], ['mh', { sm:1, md: 2, lf: 3 } ] ]
    .filter((options) => !!options[1])
    .map(([position, value]) => createClasses(prefix, position, value));

export default generateClassNames;
