import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InputSlider from 'rc-slider';
import RcSlider from 'react-slick';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
    };
  }

  onSliderChange = (value) => {
    this.slider.slickGoTo(value);
  };

  render() {
    const defaultSettings = {
      arrows: false,
      centerMode: true,
      className: 'center carousel',
      dots: false,
      easing: true,
      infinite: true,
      swipeToSlide: true,
      touchThreshold: 200,
      beforeChange: (_current, next) => this.setState({ slideIndex: next }),
    };

    const { children, scrollbar, sectionName, ...settings } = this.props;
    const { slideIndex } = this.state;

    return (
      <>
        <RcSlider
          ref={(slider) => {
            this.slider = slider;
          }}
          {...defaultSettings}
          {...settings}
        >
          {children}
        </RcSlider>
        <div
          className={classNames('carousel__slider-container', {
            [`carousel__slider-container--${sectionName}`]: sectionName, // dynamically set class if sectionName exists
            'is-shown': scrollbar,
          })}
        >
          <InputSlider onChange={this.onSliderChange} value={slideIndex} min={1} max={children.length} />
        </div>
      </>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  scrollbar: PropTypes.bool,
  sectionName: PropTypes.string, // for custom slider styles
};

Slider.defaultProps = {
  scrollbar: false,
  sectionName: '',
};

export default Slider;
