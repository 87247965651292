import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Copy from '../components';

const Paragraph = ({ className, size, ...rest }) => (
  <Copy className={classnames('p', { [`p--${size}`]: size }, className)} {...rest} />
);

Paragraph.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'small']),
};

Paragraph.defaultProps = {
  as: 'p',
  className: undefined,
  size: undefined,
};

export default Paragraph;
