export default {
  title: 'The BOXT price promise',
  copy: `Let's save you some time. We’re so confident we can give you the best
    fixed price installation that if we can’t beat a like-for-like quote we'll
    give you a £50 Amazon voucher.`,
  cta: {
    copy: 'Read more details here',
    url: '/boilers/the-boxt-price-promise',
  },
};
