import React from 'react';
import PropTypes from 'prop-types';

import H1 from 'Patterns/copy/H1';

import Product from './Product/Product';
import getProducts from './ProductSelection.data';

const ProductSelection = ({ headline, isHomeCoverEnabled, hasExtendedProductList }) => {
  const products = getProducts(isHomeCoverEnabled, hasExtendedProductList);

  return (
    <section className="product-selection" id="react-product-selection">
      {headline && (
        <div className="l-container text-center">
          <H1 bottom={9} as="h2">
            {headline}
          </H1>
        </div>
      )}

      <div className="g">
        {products.map((product) => (
          <Product product={product} key={product.key} linkText="Find out more" />
        ))}
      </div>
    </section>
  );
};

ProductSelection.propTypes = {
  headline: PropTypes.string,
  isHomeCoverEnabled: PropTypes.bool,
  hasExtendedProductList: PropTypes.bool,
};

ProductSelection.defaultProps = {
  headline: 'Ready to get started?',
  isHomeCoverEnabled: false,
  hasExtendedProductList: false,
};

export default ProductSelection;
