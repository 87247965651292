const checkCookiesAreEnabledInAllBrowser = () => {
  try {
    // The following check is for IE11 as navigator.cookieEnabled always returns true.
    document.cookie = 'cookietest=1'; // Create cookie
    const cookieIsEnabled = document.cookie.indexOf('cookietest=') !== -1;
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'; // Delete cookie

    return cookieIsEnabled;
  } catch (e) {
    return false;
  }
};

const cookiesAreEnabled = navigator.cookieEnabled && checkCookiesAreEnabledInAllBrowser();

export default cookiesAreEnabled;
