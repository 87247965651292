import AsFeaturedIn from 'Screens/home/features/AsFeaturedIn';
import HomeHero from 'Screens/home/features/Hero';
import PricePromise from 'Screens/home/features/PricePromise';
import ProductSelection from 'Screens/home/features/ProductSelection';

import { addApplets } from './applets';

const applets = [
  {
    init: HomeHero,
    el: 'react-home-hero',
  },
  {
    init: AsFeaturedIn,
    el: 'react-as-featured-in',
  },
  {
    init: ProductSelection,
    el: 'react-product-selection',
  },
  {
    init: PricePromise,
    el: 'react-price-promise',
  },
];

addApplets(applets);
