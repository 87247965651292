import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ImgixProxy from 'Patterns/ui/ImgixProxy';

const FeaturedInSlide = ({ imageFolder, itemClassName, item }) => (
  <div className="featured-in-slide">
    <ImgixProxy
      className={classNames('featured-in-slide__img', itemClassName)}
      src={require(`Images/${imageFolder}/${item.logo}.svg`)}
      width="auto"
      alt={item.name}
    />
  </div>
);

FeaturedInSlide.propTypes = {
  imageFolder: PropTypes.string.isRequired,
  itemClassName: PropTypes.string,
  item: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

FeaturedInSlide.defaultProps = {
  itemClassName: '',
};

export default FeaturedInSlide;
