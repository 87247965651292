import React from 'react';
import PropTypes from 'prop-types';

import { createKey } from 'Helpers';
import H3 from 'Patterns/copy/H3';
import Spacing from 'Patterns/layout/Spacing';
import Slider from 'Patterns/others/Slider';

import FeaturedInSlide from './components/FeaturedInSlide';

const FeaturedInSlider = ({ header, slides, imageFolder, itemClassName, settings }) => (
  <Spacing as="section" mv={4} className="featured-in-slider">
    <div className="featured-in-slider__container">
      <H3 bottom={6} align="center">
        {header}
      </H3>
      <Slider sectionName="featured-in-slider" {...settings}>
        {slides.map((item) => (
          <FeaturedInSlide
            key={createKey(item.name)}
            itemClassName={itemClassName}
            imageFolder={imageFolder}
            item={item}
          />
        ))}
      </Slider>
    </div>
  </Spacing>
);

FeaturedInSlider.propTypes = {
  header: PropTypes.string.isRequired,
  imageFolder: PropTypes.string.isRequired,
  itemClassName: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  settings: PropTypes.shape({}),
};

FeaturedInSlider.defaultProps = {
  itemClassName: '',
  settings: {
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: 'ease-out',
    initialSlide: 3,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480, // max-width
        settings: {
          initialSlide: 1,
          slidesToShow: 3,
        },
      },
    ],
  },
};

export default FeaturedInSlider;
