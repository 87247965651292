import React from 'react';
import ReactDOM from 'react-dom';

import HomeHero from './Hero';

const Hero = (el) => {
  const content = JSON.parse(el.dataset.content || null);
  ReactDOM.render(<HomeHero content={content} />, el);
};

export default Hero;
