import currencyFormatter from './currency';

const getPrice = (price, quantity) => {
  if (price > 0) {
    return currencyFormatter(price, quantity);
  }

  if (price < 0) {
    return `-${currencyFormatter(price)}`;
  }

  return 'FREE';
};

export default getPrice;
