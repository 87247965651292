import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

import dangerouslySetInnerHTML from 'Helpers/dangerouslySetInnerHTML';
import Button from 'Patterns/controls/Button';
import H3 from 'Patterns/copy/H3';
import Hero from 'Patterns/copy/Hero';
import TrustpilotWidget from 'Patterns/others/TrustpilotWidget';
import PropositionBanner from 'Patterns/ui/LandingHero/components/PropositionBanner';

import Background from './components/Background';

const checkLength = (active, length) => (active + 1 > length - 1 ? 0 : active + 1);

const HomeHero = ({ title, copy, words, ctaText, ctaTarget, section, ctaClick, content }) => {
  const [active, setActive] = useState({ current: 0, prev: words.length - 1 });
  const timer = 3000;

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((currentActive) => ({
        current: checkLength(currentActive.current, words.length),
        prev: checkLength(currentActive.prev, words.length),
      }));
    }, timer);
    return () => clearInterval(interval);
  }, [words.length]);

  const cta = {
    text: content?.cta_text || ctaText,
    theme: content?.cta_theme || 'jade',
  };
  const titleTheme = content?.title_theme || 'dark';

  const isLocalBackground = useMemo(() => {
    if (!content) return true;
    return Object.keys(content)
      .filter((key) => key.includes('img'))
      .find((key) => isEmpty(content[key]));
  }, [content]);

  const propositionBannerContent = content?.modules?.find(({ type }) => type === 'proposition_banner');

  return (
    <>
      {propositionBannerContent && <PropositionBanner content={propositionBannerContent.fields} hasSpacing={false} />}
      <div className={classNames('hero', { [`hero--${section}`]: section })}>
        {isLocalBackground ? (
          <div className="hero__image-container">
            <div className="hero__image-tint" />
            <div className="hero__image" />
          </div>
        ) : (
          <Background content={content} />
        )}
        <div
          className={classNames('hero__copy-container', {
            'l-container': section === 'home-cover',
          })}
        >
          <div className="hero__copy-inner-container">
            {!content?.title && words.length > 0 ? (
              <Hero bottom={5} theme={titleTheme} className="hero__title">
                <span className="hero__animation" key={`active-${active.current}`}>
                  <span className="hero__current-word">{words[active.current]}</span>
                  <span className="hero__prev-word">{words[active.prev]}</span>
                </span>
                <span>{` ${title}`}</span>
              </Hero>
            ) : (
              <Hero bottom={5} theme={titleTheme} className="hero__title">
                {content?.title || title}
              </Hero>
            )}
            <H3 theme={content?.subtitle_theme || 'dark'} weight="normal" bottom={7} className="hero__copy">
              {content?.subtitle ? dangerouslySetInnerHTML(content.subtitle) : copy}
            </H3>

            <Button
              size="large"
              shadow={1}
              theme={cta.theme}
              component="scroll-to"
              targetId={ctaTarget}
              onClick={ctaClick}
            >
              {cta.text}
            </Button>
          </div>
          <TrustpilotWidget
            className="hero__trustpilot"
            height="28px"
            templateId="5406e65db0d04a09e042d5fc"
            theme="dark"
            width="100%"
          />
        </div>
      </div>
    </>
  );
};

HomeHero.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  words: PropTypes.arrayOf(PropTypes.string),
  ctaText: PropTypes.string.isRequired,
  ctaTarget: PropTypes.string.isRequired,
  section: PropTypes.string,
};

HomeHero.defaultProps = {
  words: [],
  section: '',
};

export default HomeHero;
