import { useCallback, useEffect, useMemo, useState } from 'react';

const useSegmentId = () => {
  const [segmentId, setSegmentId] = useState(localStorage?.getItem('ajs_anonymous_id')?.slice(1, -1));

  const controller = useMemo(() => new AbortController(), []);

  const handleStorageChange = useCallback(() => {
    const id = localStorage.getItem('ajs_anonymous_id')?.slice(1, -1);
    if (id && !segmentId) {
      setSegmentId(id);
      controller.abort();
    }
  }, [segmentId, controller]);

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange, controller.signal);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [controller.signal, handleStorageChange]);

  return { segmentId };
};

export default useSegmentId;
