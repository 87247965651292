import classnames from 'classnames';
import PropTypes from 'prop-types';

import getSpacing from './helpers';
import responsiveSpacingPropType from './propTypes';

const withSpacing = (Component) => {
  const WrappedComponent = ({
    mh, // margin horizontal
    mv, // margin vertical
    mt, // margin top
    mb, // margin bottom
    ml, // margin left
    mr, // margin right
    ma, // margin all
    className,
    ...componentProps
  }) => {
    const spacingClassNames = getSpacing({ mh, mv, mt, mb, ml, mr, ma });
    return <Component className={classnames(spacingClassNames, className)} {...componentProps} />;
  };

  // preserve original component name, for prop type error
  WrappedComponent.displayName = `withSpacing(${Component.name || 'ANONYMOUS'})`;

  WrappedComponent.propTypes = {
    mh: responsiveSpacingPropType,
    mv: responsiveSpacingPropType,
    ma: responsiveSpacingPropType,
    mt: responsiveSpacingPropType,
    mb: responsiveSpacingPropType,
    mr: responsiveSpacingPropType,
    ml: responsiveSpacingPropType,
    className: PropTypes.string,
  };

  WrappedComponent.defaultProps = {
    mh: null,
    mv: null,
    ma: null,
    mt: null,
    mb: null,
    mr: null,
    ml: null,
    className: '',
  };

  return WrappedComponent;
};

export default withSpacing;
