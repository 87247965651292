const onVisitorExposedHelper = ({ fromFlag }) => {
  const flagList = window.sessionStorage.getItem('flagList');
  const segmentAnalytics = window.analytics;
  const flagKey = fromFlag?.key;
  if (!flagList?.split(',').includes(flagKey) && segmentAnalytics) {
    const { campaignId, isReference, variationGroupId, variationId } = fromFlag?.metadata;
    const joinedList = flagList ? flagList.concat(`,${flagKey}`) : flagKey;
    segmentAnalytics.track('flagship_test_seen', {
      campaignName: flagKey,
      campaignID: campaignId,
      isReference,
      variationGroupID: variationGroupId,
      variationID: variationId,
    });
    window.sessionStorage.setItem('flagList', joinedList);
  }
};

export default onVisitorExposedHelper;
