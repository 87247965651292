import { isTomorrow, parseISO } from 'date-fns';

import currencyFormatter from './currency';

const getAvailability = (date) => {
  if (!date) {
    return 'None';
  }

  if (isTomorrow(parseISO(date))) {
    return 'Tomorrow';
  }

  return 'Future';
};

const createKeysForYieldify = (date, discount, postcode) => {
  window.dataLayer = window.dataLayer || [];

  const hasDiscount = discount < 0 && !!discount;
  const availabilityInfo = {
    availability: getAvailability(date),
    ...(date && { nextAvailableDate: date }),
    discountAvailable: hasDiscount ? 'Yes' : 'No',
    ...(hasDiscount && { discount: currencyFormatter(discount) }),
    outcode: postcode,
  };

  window.dataLayer.push(availabilityInfo);

  return availabilityInfo;
};

export default createKeysForYieldify;
