import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Copy from '../components';

const Hero = ({ className, ...rest }) => <Copy className={classnames('copy-hero', className)} {...rest} />;

Hero.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
};

Hero.defaultProps = {
  as: 'h1',
  className: undefined,
};

export default Hero;
