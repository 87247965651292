import React from 'react';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';

const { boxtEnv } = window;

const ImgixProxy = ({ alt, className, src, srcSetOptions, sizes, width }) => {
  const shouldUseImgix = ['production', 'uat'].includes(boxtEnv);

  if (shouldUseImgix) {
    return (
      <Imgix
        className={className}
        domain={boxtEnv === 'production' ? 'boxt-fe.imgix.net' : 'boxt-fe-staging.imgix.net'}
        src={src}
        srcSetOptions={srcSetOptions}
        width={width}
        sizes={sizes}
        alt={alt}
        disableLibraryParam
      />
    );
  }
  return <img className={className} src={src} width={width || 'auto'} alt={alt} />;
};

ImgixProxy.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  // either width or sizes should be specified
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sizes: PropTypes.string,
  srcSetOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ImgixProxy.defaultProps = {
  alt: '',
  className: undefined,
  width: undefined,
  sizes: undefined,
  srcSetOptions: undefined,
};

export default ImgixProxy;
