import PropTypes from 'prop-types';

import { PRODUCT_TYPE } from './constants';

const productTypePropTypes = PropTypes.oneOf([
  PRODUCT_TYPE.AIRCON,
  PRODUCT_TYPE.BOILER,
  PRODUCT_TYPE.EV,
  PRODUCT_TYPE.HOME_COVER_CAMPAIGN,
]);

const butterFieldWithName = PropTypes.shape({
  meta: PropTypes.shape({ id: PropTypes.number }),
  name: PropTypes.string,
  slug: PropTypes.string,
});

const propositionItemProptypes = PropTypes.shape({
  text: PropTypes.string,
  icon: PropTypes.string,
  icon_alt: PropTypes.string,
  text_theme: butterFieldWithName,
});
const propositionFieldsProptypes = PropTypes.shape({
  bg: butterFieldWithName,
  id: PropTypes.string,
  animation_delay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  proposition_item: PropTypes.arrayOf(propositionItemProptypes),
});

const contentProptypes = PropTypes.shape({
  background: PropTypes.string,
  badge_img: PropTypes.string,
  cta_text: PropTypes.string,
  cta_theme: PropTypes.string,
  info: PropTypes.arrayOf(PropTypes.shape({ line: PropTypes.string })),
  modules: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string, fields: propositionFieldsProptypes })),
  info_theme: PropTypes.string,
  is_animated: PropTypes.bool,
  primary_img: PropTypes.string,
  primary_img_2x: PropTypes.string,
  primary_img_mobile: PropTypes.string,
  primary_img_mobile_2x: PropTypes.string,
  secondary_img: PropTypes.string,
  secondary_img_2x: PropTypes.string,
  subtitle: PropTypes.string,
  subtitle_theme: PropTypes.string,
  title: PropTypes.string,
});

export { contentProptypes, productTypePropTypes, propositionFieldsProptypes, propositionItemProptypes };
